import type { Content, GroupField } from "@prismicio/client";
import { FilledContentRelationshipField } from "@prismicio/types";
import type { GetStaticProps, InferGetStaticPropsType, NextPage } from "next";
import dynamic from "next/dynamic";
import { PrismicRichText } from "@prismicio/react";

import PageLayout from "@/layouts/PageLayout";
import * as prismicR from "@prismicio/client";

import { HomepageTypeDocument, Simplify } from "@/prismicio-types";
import {
  LocalBusinessJsonLd,
  LogoJsonLd,
  NextSeo,
  OrganizationJsonLd,
} from "next-seo";
import { createClient } from "../prismicio";
import { chalk, log } from "@/utils/log";

interface IText {
  text: string;
}
interface IFeaturedImage {
  url: string;
}
interface TestSnippet {
  uid: string;
  title: IText[];
  description: IText[];
  featuredImage: IFeaturedImage;
  totalTimeLimit: string;
}

const DynamicSectionFeaturedTestCard = dynamic(
  () => import("@/views/SectionFeaturedTestsCard"),
  {
    ssr: true,
  }
);

const Home: NextPage = ({
  page,
  organisationData,
  defaultSeo,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  log(chalk.magentaBright("Home => page:"), page);
  // stale infinity because the prismic data never changes
  // const { data: testData } = useQuery({
  //   queryKey: [QueryKeys.TESTS],
  //   queryFn: getTestData,
  //   staleTime: Infinity,
  // });
  const pageSeo = page.data.seo[0];
  const {
    data: { featuredTests },
  } = page as HomepageTypeDocument & {
    data: {
      featuredTests: {
        test: FilledContentRelationshipField<
          string,
          string,
          Content.TestTypeDocumentData
        >;
      }[];
    };
  };
  const { heading, bodyCopy } = page.data;
  // const featuredTests: [any] = page.data.featuredTests;
  // const { testOrders } = useUser();

  return (
    <PageLayout>
      <LogoJsonLd
        logo={organisationData[0].logo.url || ""}
        url={organisationData[0].url || window.location.toString()}
      />
      <LocalBusinessJsonLd
        id={organisationData[0].name || ""}
        address={{
          streetAddress: organisationData[0].streetAddress || "",
          addressLocality: organisationData[0].addresslocality || "",
          addressRegion: organisationData[0].addressRegion || "",
          postalCode: organisationData[0].postalCode || "",
          addressCountry: organisationData[0].addressCountry || "",
        }}
        type="MedicalBusiness"
        images={[organisationData[0].logo.url, pageSeo.featuredImage.url]}
        description={defaultSeo.defaultDescription || ""}
        name={organisationData[0].name || ""}
        url={organisationData[0].url || window.location.toString()}
      />
      <OrganizationJsonLd
        name={organisationData[0].name || ""}
        url={organisationData[0].url || window.location.toString()}
        logo={organisationData[0].logo.url || ""}
        legalName={organisationData[0].legalName || ""}
        address={{
          streetAddress: organisationData[0].streetAddress || "",
          addressLocality: organisationData[0].addresslocality || "",
          addressRegion: organisationData[0].addressRegion || "",
          postalCode: organisationData[0].postalCode || "",
          addressCountry: organisationData[0].addressCountry || "",
        }}
      />
      <NextSeo
        title={pageSeo.title}
        description={
          prismicR.asText(pageSeo.metaDescription) ||
          defaultSeo.defaultDescription
        }
        openGraph={{
          siteName: defaultSeo.siteName,
          title: pageSeo.title,
          images: [
            {
              url: pageSeo.featuredImage.url,
              width: pageSeo.featuredImage.dimensions.width,
              height: pageSeo.featuredImage.dimensions.height,
              alt: pageSeo.featuredImage.alt,
            },
          ],
        }}
      />
      <div className="relative flex-1   bg-theme/20 py-8 sm:py-12 lg:py-24 flex flex-col justify-center">
        <div className="relative pb-4 lg:pb-12">
          {heading || bodyCopy ? (
            <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl  sm:px-8 lg:px-16 lg:max-w-9xl pb-6 lg:pb-10">
              {heading ? (
                // <h1 className="  text-3xl font-extrabold text-gray-50   sm:text-4xl">
                //   {heading[0].text}
                // </h1>
                <PrismicRichText
                  field={heading}
                  components={(type, node, content, children) => {
                    return (
                      <h1 className="  text-4xl font-extrabold    sm:text-5xl">
                        {content}
                      </h1>
                    );
                  }}
                />
              ) : null}
              {bodyCopy ? (
                <PrismicRichText
                  field={bodyCopy}
                  components={(type, node, content, children) => {
                    return (
                      <p className="mt-5 mx-auto max-w-4xl text-xl ">
                        {content}
                      </p>
                    );
                  }}
                />
              ) : null}
            </div>
          ) : null}

          <DynamicSectionFeaturedTestCard featuredTests={featuredTests} />
        </div>
      </div>
    </PageLayout>
  );
};

export default Home;

export const getStaticProps: GetStaticProps<{
  defaultSeo: Simplify<Content.SettingsDocumentDataSiteItem>;
  page: Content.HomepageTypeDocument<string>;
  organisationData: GroupField<
    Simplify<Content.SettingsDocumentDataOrganisationItem>
  >;
}> = async (ctx) => {
  const { previewData } = ctx;
  const client = createClient({ previewData });
  const defaultSettings = await client.getSingle("settings");
  const { data: settings } = defaultSettings;
  const organisationData = settings.organisation;
  const defaultSeo = defaultSettings?.data.site[0];
  const page = await client.getSingle("homepage-type", {
    graphQuery: `
    {
      homepage-type {
        seo
        heading
        bodyCopy
        featuredTests {
          test {
            ...on test_type {
              description
              test_name
              test_long_name
              featured_image
              is_live
              price_id
            }
          }
        }
      }
    }
  `,
  });
  // const supabase = createPagesServerClient<Database>(ctx);

  // const user = await getMe(ctx);

  // const queryClient = new QueryClient();

  // if (user) {
  //   const getOrders = async () => {
  //     const query = supabase
  //       .from("orders")
  //       .select("id, test_uid, created_at, order_status")
  //       .eq("user_id", user.id);

  //     query.order("created_at", { ascending: false });
  //     const { data } = await query;
  //     return data;
  //   };

  //   await queryClient.setQueryData([QueryKeys.Me, user.id], user);

  //   await queryClient
  //     .fetchQuery({
  //       queryKey: filterEmptyQueryKeys([QueryKeys.ORDERS, user.id]),
  //       queryFn: getOrders,
  //     })
  //     .then((orders) => {
  //       orders.forEach((order) => {
  //         queryClient.setQueryData<Array<typeof order>>(
  //           [QueryKeys.ORDERS, user.id, order.test_uid],
  //           (prevData) => {
  //             if (prevData) {
  //               prevData.push(order);
  //               return prevData;
  //             }
  //             return [order];
  //           }
  //         );
  //         queryClient.setQueryData([QueryKeys.ORDER, user.id, order.id], order);
  //       });
  //     });
  // }
  console.log("PAGE", page);
  return {
    // props: { page, dehydratedState: dehydrate(queryClient) },
    props: { page, organisationData, defaultSeo },
  };
};
